type TConfig = {
  common: string[];
  feature: string;
};

export const translationConfig: TConfig = {
  common: [''],
  feature: 'Feature.Catalog'
};
export const buttonTranslationConfig: TConfig = {
  common: [''],
  feature: 'Feature.ItemConfiguration'
};

export default translationConfig;
